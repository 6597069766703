export const apiVersion = '/v1'
export const apiVersionV2 = '/v2'

/*
* allow to change API_URL in runtime
* how to deploy frontend branch - https://elopay.atlassian.net/wiki/spaces/EPD/pages/2457862151/How+to+deploy+frontend+branch
* */
const API_URL = process.env.NEXT_PUBLIC_API_URL
const NEXT_PUBLIC_API_CLIENT_URL = process.env.NEXT_PUBLIC_API_CLIENT_URL
const NEXT_PUBLIC_WEB_HOST = process.env.NEXT_PUBLIC_WEB_HOST

const apis = {
  test: 'https://api.test.elopage.com',
  staging: 'https://api-cdn.staging.elopage.com',
  uat: 'https://api-cdn.uat.elopage.com',
  production: 'https://api-cdn.elopage.com',
} as const

const newApis = {
  test: 'https://api.test.myablefy.com',
  staging: 'https://api-cdn.staging.myablefy.com',
  uat: 'https://api-cdn.uat.myablefy.com',
  production: 'https://api-cdn.myablefy.com',
} as const

export const apiEndpoints = {
  test: apis.test,
  development: NEXT_PUBLIC_API_CLIENT_URL || apis.staging,
  staging: NEXT_PUBLIC_API_CLIENT_URL || apis.staging,
  uat: NEXT_PUBLIC_API_CLIENT_URL || apis.uat,
  production: NEXT_PUBLIC_API_CLIENT_URL || apis.production,
} as const

export const newApiEndpoints = {
  test: newApis.test,
  development: NEXT_PUBLIC_API_CLIENT_URL || newApis.staging,
  staging: NEXT_PUBLIC_API_CLIENT_URL || newApis.staging,
  uat: NEXT_PUBLIC_API_CLIENT_URL || newApis.uat,
  production: NEXT_PUBLIC_API_CLIENT_URL || newApis.production,
} as const

export const apiEndpointsSSR = {
  development: apis.staging,
  staging: API_URL ?? apis.staging,
  uat: API_URL ?? apis.uat,
  production: API_URL ?? apis.production,
} as const

export const apiEndpointsCommunity = {
  development: 'https://api.community.staging.elopage.com',
  staging: 'https://api.community.staging.elopage.com',
  uat: 'https://api.community.uat.elopage.com',
  production: 'https://api.community.elopage.com',
} as const

export const webProtocols = {
  development: 'http',
  staging: 'https',
  uat: 'https',
  production: 'https',
} as const

export const webHosts = {
  test: NEXT_PUBLIC_WEB_HOST || 'localhost:8080',
  development: NEXT_PUBLIC_WEB_HOST || 'localhost:8080',
  staging: NEXT_PUBLIC_WEB_HOST || 'staging.elopage.com',
  uat: NEXT_PUBLIC_WEB_HOST || 'uat.elopage.com',
  production: NEXT_PUBLIC_WEB_HOST || 'elopage.com',
} as const

export const newWebHosts = {
  test: NEXT_PUBLIC_WEB_HOST || 'staging.myablefy.com',
  development: NEXT_PUBLIC_WEB_HOST || 'staging.myablefy.com',
  staging: NEXT_PUBLIC_WEB_HOST || 'staging.myablefy.com',
  uat: NEXT_PUBLIC_WEB_HOST || 'uat.myablefy.com',
  production: NEXT_PUBLIC_WEB_HOST || 'myablefy.com',
} as const

export const marketingWebHosts = {
  test: 'staging.ablefy.io',
  development: 'staging.ablefy.io',
  staging: 'staging.ablefy.io',
  uat: 'uat.ablefy.io',
  production: 'ablefy.io',
} as const
export const facebookAppId = '1512219545743496'

export const cookiesDomains = {
  development: 'localhost',
  staging: '.staging.elopage.com',
  uat: '.uat.elopage.com',
  production: '.elopage.com',
}
export const newCookiesDomains = {
  development: 'localhost',
  staging: '.staging.myablefy.com',
  uat: '.uat.myablefy.com',
  production: '.myablefy.com',
}

export const reCaptchaKey = '6LfwMlgfAAAAAM4zLLAcEQVRXCq0fLxloNoZIyIk'

export const amplifyConfig = {
  development: {
    aws_appsync_graphqlEndpoint: 'https://77nhynlszjcidn62nual5aqd2a.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  },
  staging: {
    aws_appsync_graphqlEndpoint: 'https://77nhynlszjcidn62nual5aqd2a.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  },
  uat: {
    aws_appsync_graphqlEndpoint: 'https://4n7tjdjbengojpbb55zrf7mkxu.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  },
  production: {
    aws_appsync_graphqlEndpoint: 'https://zej32h344bg2dbvmbggdpzyefu.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  },
} as const

export const sentryConfig = {
  development: {
    publicDsn: ''
  },
  staging: {
    publicDsn: ''
  },
  uat: {
    publicDsn: ''
  },
  production: {
    publicDsn: 'https://aafd9266a0544f6eb19d5fa618912e7e@o407470.ingest.sentry.io/6599603'
  }
} as const

export const sentryConfigCabinets = {
  development: 'https://966ff7d740f658a54bdb97588eefba89@o407470.ingest.sentry.io/4505679922790400',
  staging: 'https://966ff7d740f658a54bdb97588eefba89@o407470.ingest.sentry.io/4505679922790400',
  uat: 'https://966ff7d740f658a54bdb97588eefba89@o407470.ingest.sentry.io/4505679922790400',
  production: 'https://966ff7d740f658a54bdb97588eefba89@o407470.ingest.sentry.io/4505679922790400',
} as const

export const SDK_KEY = 'b136e032-69dc-4afb-8e75-29bb70da1971'
